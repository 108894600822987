import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  lazy,
  Suspense,
} from "react";
import { useNavigate } from "react-router-dom";
import "./ScrollMenu.css";
import axios from "axios";
import "../components/shared/Themes.css";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import EmptyLike from "../components/shared/empty-like.png";
import Like from "../components/shared/like.png";
import CommentIcon from "../components/shared/comment.png";
import ShareIcon from "../components/shared/share.png";
import KebabIcon from "../components/shared/kebab.png";
import Modal from "../components/shared/Modal";
import Login from "../components/shared/Login";
import Comments from "../components/shared/Comments";
import { AppContext } from "../App";

const ImgFile = lazy(() => import("../components/shared/ImgFile"));

/* 
  Component: AutoPlaySilentVideo
  Purpose: Autoplay video silently in a loop with no controls, used to display food videos.
*/
const AutoPlaySilentVideo = (props) => {
  const videoRef = useRef(undefined);

  useEffect(() => {
    videoRef.current.defaultMuted = true; // Mute video by default
  }, []);

  return (
    <ReactPlayer
      className={props.className}
      ref={videoRef}
      loop
      playing={props.startPlaying}
      muted
      playsinline={true}
      url={[{ src: props.src, type: "video/mp4" }]}
      width="100%"
      height="100%"
      controls={false}
      alt={props.alt}
      config={{
        file: {
          attributes: {
            controlsList: "nofullscreen", // Disable full screen control
          },
        },
      }}
    />
  );
};

/* 
  Component: NoContent
  Purpose: Display fallback content when no media is available for a restaurant item.
*/
const NoContent = (props) => {
  const fileInput = useRef(null);

  // Trigger file input dialog
  const chooseFile = () => {
    fileInput.current.click();
  };

  return (
    <div className="no-content-background">
      <div className="no-content">
        <div className="no-content-prompt">
          <div className="oops">Oops!</div>
          <div className="upload-description">
            Looks like {props.restaurantName} hasn’t uploaded any content for
            this item. Provide your own photos or video of the {props.itemName}{" "}
            and we'll dish it up.
          </div>
        </div>
      </div>
    </div>
  );
};

/* 
  Component: SecondaryActions
  Purpose: Handle actions like uploading media when no content is available.
*/
const SecondaryActions = (props) => {
  const fileInput = useRef(null);

  const [uploaded, setUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);

  // Trigger file input dialog
  const chooseFile = () => {
    fileInput.current.click();
  };

  // Handle file attachment
  const attachFile = (e) => {
    e.preventDefault();

    const files = e.target.files;

    if (!files || files.length === 0) {
      console.error("No files found.");
      return;
    }

    const file = files[0];

    const readerForContent = new FileReader();
    readerForContent.readAsDataURL(file);
    readerForContent.onloadend = () => {
      const base64String = readerForContent.result.split(",")[1];

      const postBody = {
        contentType: file.type,
        data: base64String,
        restaurantId: props.restaurantId,
        itemId: props.itemId,
      };

      setUploading(true);

      // Upload file to server
      axios
        .post(
          `${process.env.REACT_APP_DOMAIN}/api/restaurant/edit/pending-media`,
          postBody
        )
        .then((res) => {
          console.log(res);
        })
        .then(() => {
          setUploading(false);
          setUploaded(true);
        })
        .catch((err) => {
          console.error(err);
        });
    };
  };

  return (
    <div className="secondary-actions">
      <button
        className={`action-button upload-button${uploaded ? " saved" : ""}`}
        onClick={chooseFile}
      >
        {uploading ? "Uploading..." : uploaded ? "Saved!" : "Upload"}
      </button>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .gif, .mp4"
        onChange={attachFile}
      />
    </div>
  );
};

// Calculate the initial page reference once, outside the component's render cycle
const calculateInitialPageRef = (returnToIndex) =>
  Math.floor(returnToIndex / 10) + 1 || 1;

/* 
  Component: Discover
  Purpose: Main component for displaying the discover page with menu items, 
  managing user interactions, and fetching data.
*/
const Discover = () => {
  // Use the AppContext to access user state
  const { user, setUser } = React.useContext(AppContext);

  // State management for the component
  const [menuItems, setMenuItems] = useState([]);
  const [inView, setInView] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [following, setFollowing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const [likedItems, setLikedItems] = useState({});
  const [initialScrollCompleted, setInitialScrollCompleted] = useState(false); // Track initial scroll status
  const [requestingUser, setRequestingUser] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);

  // References for managing scrolling and other states
  const loadingRef = useRef(false);
  const itemRefs = useRef([]);
  const observerRef = useRef(null);
  const navigate = useNavigate();
  const lastTap = useRef(0);
  const hasScrolledToIndex = useRef(false);

  // Extract search parameters from URL
  const { search } = window.location;
  const params = new URLSearchParams(search);

  const lat = params.get("lat");
  const lng = params.get("lng");
  const returnToIndex = parseInt(params.get("returnto"), 10);

  // Initialize pageRef
  const pageRef = useRef(calculateInitialPageRef(returnToIndex));

  // References for managing pagination and scrolling
  const hasMoreRef = useRef(true);
  const hasLessRef = useRef(pageRef.current > 1);

  /* 
    Function: fetchRestaurants
    Purpose: Fetch restaurant data based on current latitude and longitude.
  */
  const fetchRestaurants = useCallback(
    async (direction = "forward") => {
      if (loadingRef.current) return;

      loadingRef.current = true;

      const pageSize = 10;
      let pageToFetch;

      if (direction === "forward") {
        pageToFetch = pageRef.current;
        pageRef.current += 1;
      } else {
        pageRef.current -= 1;
        pageToFetch = pageRef.current;
      }

      // Ensure pageToFetch is at least 1
      if (pageToFetch < 1) {
        loadingRef.current = false;
        hasLessRef.current = false;
        return;
      }

      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/restaurant/discover-by-lat-long`,
        {
          lat,
          lng,
          page: pageToFetch,
          limit: pageSize,
        }
      );

      if (res.data.length === 0) {
        if (direction === "forward") {
          hasMoreRef.current = false;
        } else {
          hasLessRef.current = false;
        }
      } else {
        if (direction === "forward") {
          console.log("Appending items to the end of the list");
          setMenuItems((prevItems) => [...prevItems, ...res.data]);
        } else {
          console.log("Prepending items to the start of the list");
          setMenuItems((prevItems) => [...res.data, ...prevItems]);
          // Adjust inView index by adding the number of new items
          setInView((prevInView) => prevInView + res.data.length);
        }
      }

      loadingRef.current = false;
    },
    [lat, lng]
  );

  useEffect(() => {
    setRequestingUser(false);
  }, []);

  // Fetch data when component mounts or lat/lng changes
  useEffect(() => {
    if (lat && lng) {
      setLatitude(lat);
      setLongitude(lng);
      fetchRestaurants();
    } else {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);
        fetchRestaurants();
      });
    }
  }, [lat, lng, fetchRestaurants]);

  // Set up IntersectionObserver to load more items when the user scrolls
  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = itemRefs.current.findIndex(
              (item) => item === entry.target
            );

            if (index !== inView) {
              setInView(index);

              // Trigger API call when scrolling down near the bottom
              if (
                index >= menuItems.length - 3 &&
                hasMoreRef.current &&
                !loadingRef.current
              ) {
                fetchRestaurants("forward");
              }
              // Trigger API call when scrolling up near the top
              else if (
                index === 0 &&
                hasLessRef.current &&
                !loadingRef.current
              ) {
                fetchRestaurants("backward");
              }
            }
          }
        });
      },
      { threshold: 0.1 } // Use a smaller threshold for more sensitive detection
    );

    itemRefs.current.forEach((item) => {
      if (item) observerRef.current.observe(item);
    });

    return () => {
      observerRef.current.disconnect();
    };
  }, [menuItems, inView, fetchRestaurants]);

  // Load previous pages if a returnToIndex is specified
  useEffect(() => {
    if (!hasScrolledToIndex.current && returnToIndex && menuItems.length > 0) {
      const pageSize = 10;
      const targetIndex = returnToIndex % pageSize;
      const targetPage = Math.floor(returnToIndex / pageSize) + 1;

      console.log(
        `Start loading previous pages to reach target. Target index: ${targetIndex}, Target page: ${targetPage}, Current pageRef: ${pageRef.current}`
      );

      const loadPreviousPages = async () => {
        while (
          pageRef.current < targetPage &&
          menuItems.length <= returnToIndex
        ) {
          console.log(
            `Loading page ${pageRef.current} to reach target page ${targetPage}`
          );
          await fetchRestaurants("forward");
        }

        if (menuItems.length > targetIndex && itemRefs.current[targetIndex]) {
          itemRefs.current[targetIndex].scrollIntoView({
            behavior: "instant",
            block: "center",
          });
          setInView(targetIndex);
          hasScrolledToIndex.current = true;
          setInitialScrollCompleted(true); // Mark initial scroll as completed
        } else {
          console.log(
            `Item at target index ${targetIndex} not found yet. Total items loaded: ${menuItems.length}`
          );
        }
      };

      loadPreviousPages();
    }
  }, [returnToIndex, menuItems, fetchRestaurants]);

  // Set page background color on load
  useEffect(() => {
    document.querySelector("#theme-color").content = "#000";
    document.body.style.backgroundColor = "#000";
  }, []);

  // Function handlers for various user actions
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const openCommentModal = (e) => {
    e.stopPropagation();

    setModalContent({
      title: "Comments",
      content: (
        <Comments
          id={e.target.getAttribute("item")}
          user={user}
          exit={setRequestingUser}
        />
      ),
    });
    setModalOpen(true);
  };

  const openShareModal = (e) => {
    e.stopPropagation();

    setModalContent({ title: "Share", content: "This is a share." });
    setModalOpen(true);
  };

  const openKebabModal = (e) => {
    e.stopPropagation();

    setModalContent({ title: "Options", content: "This is a kebab." });
    setModalOpen(true);
  };

  const handleDoubleClickOrTap = (itemId) => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap.current;

    if (tapLength < 300 && tapLength > 0) {
      toggleLike(itemId); // Register double click/tap if within 300ms
    }

    lastTap.current = currentTime;
  };

  const toggleLike = (itemId) => {
    setLikedItems((prevLikedItems) => ({
      ...prevLikedItems,
      [itemId]: !prevLikedItems[itemId], // Toggle the like state
    }));
  };

  const resetExpanded = (e) => {
    e.stopPropagation();
    setExpandedItems({}); // Reset all to collapsed
  };

  const expandItem = (itemId) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId], // Toggle expansion state for the clicked item
    }));
  };

  const pauseVideo = (e) => {
    e.stopPropagation();
    document.querySelectorAll("video").forEach((video) => {
      video.pause();
    });
  };

  const unpauseVideo = (e) => {
    e.stopPropagation();
    document.querySelectorAll("video").forEach((video) => {
      video.play();
    });
  };

  const goToRestaurantMenu = (e) => {
    e.stopPropagation();

    const currentIndex = inView !== null ? inView : 0; // Default to 0 if inView is null

    navigate(
      `/r/${e.target.getAttribute(
        "restaurant"
      )}?discover=true&lat=${latitude}&lng=${longitude}&returnto=${currentIndex}`
    );
  };

  const followRestaurant = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!user) {
      // Show login modal if user is not authenticated
      setShowLoginModal(true);
    } else {
      // Handle follow action
      setFollowing((prev) => !prev);

      // Optionally, send follow/unfollow request to your backend
      // axios.post('/api/follow', { userId: user._id, itemId: item._id });
    }
  };

  const openMaps = async (e) => {
    e.stopPropagation();

    const encodedAddress = encodeURIComponent(e.target.getAttribute("address"));
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let mapsUrl;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      mapsUrl = `https://maps.apple.com/?daddr=${encodedAddress}`;
    } else if (/android/i.test(userAgent)) {
      mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
    } else {
      mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
    }

    await window.open(mapsUrl, "_blank");

    await axios.post(
      `${process.env.REACT_APP_DOMAIN}/api/restaurant/edit/referral`,
      {
        resId: e.target.getAttribute("restaurant"),
      }
    );
  };

  // Main return block for rendering the discover component
  return (
    <div className={`ScrollMenu${" bounce-signal"}`}>
      {menuItems.map((item, index) => (
        <div
          key={index + (pageRef.current - 1) * 10}
          onTouchStart={pauseVideo}
          onTouchEnd={unpauseVideo}
          onClick={() => handleDoubleClickOrTap(item._id)} // Double-click and double-tap handler
          id={`item-${item._id}`}
          item={item._id}
          className={`scrollItem`}
          ref={(element) => {
            itemRefs.current[index] = element;
          }}
        >
          {item.content.src !== "/" ? (
            item.content.contentType === "img" ? (
              <>
                <img
                  src={item.content.thumbnail}
                  className="item-image loading ken-burns"
                />
                <ImgFile
                  expanded={expandedItems[item._id]}
                  src={item.content.src}
                  alt={item.title}
                />
              </>
            ) : (
              <>
                <img
                  src={item.content.thumbnail}
                  className="item-image loading"
                />
                <AutoPlaySilentVideo
                  className={`item-image${
                    expandedItems[item._id] ? " expanded-gradient" : ""
                  }`}
                  src={item.content.src}
                  autoPlay
                  startPlaying={index === inView}
                />
              </>
            )
          ) : (
            <NoContent
              restaurantName={item.restaurantName}
              itemName={item.title}
            />
          )}
          <div className="gradient">
            <div className="item-content">
              <div
                className="item-text"
                onClick={(e) => {
                  e.stopPropagation();
                  expandItem(item._id);
                }}
              >
                <div className="item-price-container">
                  <span className="item-price">${item.price.toFixed(2)}</span> |{" "}
                  <span className="restaurant-tag">{item.restaurantName}</span>
                  <span>
                    <button
                      className={`follow-button${
                        following ? " following" : ""
                      }`}
                      onClick={followRestaurant}
                    >
                      {following ? "Following" : "Follow"}
                    </button>
                  </span>
                </div>
                <div className={`jost item-title`}>{item.title}</div>
                <div
                  className={`item-description${
                    expandedItems[item._id] ? " expanded" : " collapsed"
                  }`}
                >
                  {item.description}
                </div>
              </div>
              <div className="interaction-toolbar">
                <div className="test-option">Option</div>
                <div
                  className="interaction-option"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event bubbling
                    toggleLike(item._id); // Call the toggle function with the current item ID
                  }}
                >
                  <img
                    className={`interaction-icon${
                      likedItems[item._id] ? " liked" : ""
                    }`}
                    src={likedItems[item._id] ? Like : EmptyLike} // Change image based on liked state
                    alt="Like Button"
                  />
                </div>
                <div onClick={openCommentModal} className="interaction-option">
                  <img
                    className="interaction-icon"
                    src={CommentIcon}
                    alt="Comment Button"
                  />
                </div>
                <div onClick={openShareModal} className="interaction-option">
                  <img
                    className="interaction-icon"
                    id="share-icon"
                    src={ShareIcon}
                    alt="Share Button"
                  />
                </div>
                <div onClick={openKebabModal} className="interaction-option">
                  <img
                    className="interaction-icon"
                    id="kebab-icon"
                    src={KebabIcon}
                    alt="Options Button"
                  />
                </div>{" "}
              </div>
            </div>
            <div className="action-buttons">
              <div className="primary-actions">
                <div className="button-section">
                  <a
                    onClick={goToRestaurantMenu}
                    itemId={item._id}
                    restaurant={item.id}
                    className="action-button menu-button"
                  >
                    See Menu
                  </a>
                </div>
                <div className="button-section">
                  <a
                    onClick={openMaps}
                    address={item.location}
                    restaurant={item.id}
                    className="action-button map-button"
                  >
                    Go Here
                  </a>
                </div>
              </div>
              {item.content.src === "/" && (
                <SecondaryActions restaurantId={item.id} itemId={item.itemId} />
              )}
            </div>
          </div>
        </div>
      ))}
      {loadingRef.current && <div>Loading...</div>}
      <Modal
        show={isModalOpen}
        onClose={handleCloseModal}
        header={modalContent?.title}
      >
        {modalContent?.content}
      </Modal>
      {showLoginModal && <Login exit={() => setShowLoginModal(false)} />}
    </div>
  );
};

export default React.memo(Discover);
