import React, { useState, useEffect, createRef } from "react";
import "./Comments.css";
import SubmitArrow from "./submit-arrow.png";
import Login from "./Login";

const Comments = (props) => {
  const [value, setValue] = useState("");
  const [comments, setComments] = useState([
    {
      author: "John Doe",
      content: "This is a comment.",
    },
    {
      author: "Jane Doe",
      content: "This is another comment.",
    },
    {
      author: "John Doe",
      content: "This is also a comment.",
    },
    {
      author: "John Doe",
      content: "This is also a comment.",
    },
    {
      author: "John Doe",
      content: "This is also a comment.",
    },
    {
      author: "John Doe",
      content: "This is also a comment.",
    },
    {
      author: "John Doe",
      content: "This is also a comment.",
    },
    {
      author: "John Doe",
      content: "This is also a comment.",
    },
    {
      author: "John Doe",
      content: "This is also a comment.",
    },
  ]);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_DOMAIN}/api/restaurant/comments/${props.id}`)
  //     .then((res) => {
  //       setComments(res.data);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }, [props.id]);

  const inputRef = createRef();

  const selectInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current.focus();
  };

  const submitComment = async (e) => {
    e.preventDefault();

    await inputRef.current.blur();

    if (!props.user) {
      await props.exit(true);
      return;
    }

    if (value.length > 0) {
      await console.log(value);
    }

    await setValue("");
  };

  const changeValue = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      submitComment();
    } else {
      setValue(e.target.value);
    }
  };

  return (
    <div className="Comments">
      {comments.map((comment, index) => (
        <div key={index} className="comment">
          <div className="comment-author">{comment.author}</div>
          <div className="comment-content">{comment.content}</div>
        </div>
      ))}
      <div className="comment-actions">
        <form
          className="comment-input-container"
          onClick={selectInput}
          onSubmit={submitComment}
        >
          <input
            type="text"
            value={value}
            onChange={changeValue}
            className="comment-input"
            placeholder="Add a comment..."
            ref={inputRef}
          />
          {value.length > 0 && (
            <button
              className="comment-submit"
              type="submit"
              onClick={submitComment}
            >
              <img className="submit-arrow" src={SubmitArrow} alt="send" />
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Comments;
