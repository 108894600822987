// Login.js
import React, { useContext } from "react";
import "./Login.css";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import CorpMemphRest from "./corporate-memphis-restaurant.png";
import GoogleLogo from "./google-logo.png";
import { AppContext } from "../../App";

const Login = (props) => {
  const { user, setUser } = useContext(AppContext);

  const exit = (e) => {
    e?.stopPropagation();
    props.exit(false);
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Send id_token to backend for verification
        const response = await axios.post(
          `${process.env.REACT_APP_DOMAIN}/api/auth/google`,
          {
            token: tokenResponse.access_token,
          }
        );

        // Set user info in context
        setUser(response.data.user);

        // Close the login modal
        exit();
      } catch (error) {
        console.error("Login Failed:", error);
      }
    },
    onError: () => console.log("Login Failed"),
  });

  return (
    <div className="Login" onClick={exit}>
      <img src={CorpMemphRest} alt="background" className="login-background" />
      <div className="login-card" onClick={(e) => e.stopPropagation()}>
        <h2 className="login-title">Platefol is the world's smartest menu.</h2>
        <h3 className="login-subtitle">
          Don't order something you'll regret. See your food, and help us get to
          know your taste.
        </h3>
        <button className="login-with" onClick={loginWithGoogle}>
          <img src={GoogleLogo} className="google-logo" alt="Google Logo" />
          Login with Google
        </button>
      </div>
    </div>
  );
};

export default Login;
